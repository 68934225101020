import axios from 'axios';

import { createElementFromHTML, unwrap } from './../../utilities/js/helper';

class Pager {
    constructor ($element, options) {
        const defaults = {
            initAttr: 'data-pager',
            triggerAttr: 'trigger',
            pageAttr: 'page',
            resultsSelectorAttr: 'results'
        };

        this.settings = Object.assign({}, defaults, options);
        this.$pager = $element;
        this.$pagerTrigger = this.$pager.querySelector('[' + this.settings.initAttr + '="' + this.settings.triggerAttr + '"]');
        this.$pagerValue = this.$pager.querySelector('[' + this.settings.initAttr + '="' + this.settings.pageAttr + '"]');
        this.pagerResultsSelector = this.$pager.querySelector('[' + this.settings.initAttr + '="' + this.settings.resultsSelectorAttr + '"]');
        this.$pagerForm = this.$pager.closest('form');
        this.$loadingSpinner = createElementFromHTML(window.OPTIONS.loader);

        if (typeof this.pagerResultsSelector === 'object' && this.pagerResultsSelector.tagName.toLowerCase() === 'input') {
            this.pagerResultsSelector = this.pagerResultsSelector.value;
        }

        this.initialize();
    }

    initialize () {
        this.setEvents();
    }

    setEvents () {
        if (this.$pagerForm === null) {
            this.$pagerTrigger.addEventListener('click', (e) => {
                const uri = this.$pagerTrigger.getAttribute('href');
                this.loadPage(uri);
                e.preventDefault();
            });
        } else {
            this.$pagerForm.addEventListener('submit', (e) => {
                const page = this.$pagerValue.value;
                const uri = this.$pagerForm.getAttribute('action');
                this.$pagerValue.value = parseInt(page) + 1;
                this.loadPage(uri);
                e.preventDefault();
            });
        }
    }

    loadPage (uri) {
        let formData = null;
        if (this.$pagerForm !== null) {
            formData = new FormData(this.$pagerForm);
        }

        this.$pagerTrigger.setAttribute('disabled', true);
        this.$pagerTrigger.classList.add('button--disabled');
        this.$pagerTrigger.appendChild(this.$loadingSpinner);

        axios({
            method: 'POST',
            url: uri,
            data: formData,
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        })
            .then((result) => {
                if (result && result.data) {
                    const $result = createElementFromHTML(result.data);
                    const $pagerResult = $result.querySelector(this.pagerResultsSelector);

                    this.$pager.replaceWith($pagerResult);

                    const contentUpdateEvent = new CustomEvent('content.loaded', {
                        view: window,
                        bubbles: true,
                        cancelable: false,
                        detail: {
                            $context: $pagerResult
                        }
                    });
                    window.dispatchEvent(contentUpdateEvent);

                    unwrap($pagerResult);

                    window.checkInview(window.eventScroller);
                }
            })
            .catch((error) => {
                throw Error(error);
            });
    }
}

export { Pager };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$pager = $context.querySelectorAll('[data-pager="root"]');
        for (let i = 0; i < $$pager.length; i++) {
            const $pager = $$pager[i];

            const pagerAPI = new Pager($pager);
            $pager.API = pagerAPI;
        }
    }
});
